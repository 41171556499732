/**
* Created by hansxing on 2018/8/7.
*/
<style lang="scss" scoped>
    .supplier-list {
        .user-list-table {
            background: #fff;
            margin-top: 10px;
            /*padding: 0 20px;*/
            .not-through-popover {
                display: inline-block;
                cursor: pointer;
                .el-popover__reference {
                    color: #FF3B30;
                }
            }
        }
    }
</style>
<style lang="scss">
    .supplier-list {

    }
</style>
<template>

    <div>
        <el-main class="com-eTrip-section">
            <div class="eTrip-section-cont supplier-list">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form :inline="true" size="small">
                            <el-form-item label="">
                                <el-input v-model="supplierInfoInput.corporateName" placeholder="请输入公司名称"></el-input>
                                <!--<el-autocomplete-->
                                <!--class="inline-input"-->
                                <!--v-model="supplierInfoInput.corporateName"-->
                                <!--:fetch-suggestions="corporateNameList"-->
                                <!--placeholder="请输入公司名称">-->
                                <!--<template slot-scope="{ item }">-->
                                <!--<div class="name">{{ item.value }}</div>-->
                                <!--&lt;!&ndash;<span class="addr">{{ item.address }}</span>&ndash;&gt;-->
                                <!--</template>-->
                                <!--</el-autocomplete>-->
                            </el-form-item>
                            <el-form-item label="">
                                <el-input v-model="supplierInfoInput.supplierId" placeholder="供应商ID"></el-input>
                            </el-form-item>
                            <el-form-item label="">
                                <el-input v-model="supplierInfoInput.belongedOperatorName"
                                          placeholder="所属运营商名称"></el-input>
                            </el-form-item>
                            <el-form-item label="" style="width:166px">
                                <el-radio-group v-model="supplierTypeState.screenContract">
                                    <el-radio-button :label="-1">全部</el-radio-button>
                                    <el-radio-button :label="0">有效
                                        <!--<span v-text="supplierCompanyTable.contractWillExpireCount"></span>-->
                                    </el-radio-button>
                                    <el-radio-button :label="1">无效
                                        <!--<span v-text="supplierCompanyTable.contractExpiredCount"></span>-->
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div slot="list-search-after" class="list-search-after_sec">
                        <el-button @click="supplierScreenResetClick()">重置</el-button>
                        <el-button type="primary" @click="supplierScreenBtnClick()">搜索</el-button>
                    </div>

                </com-list-search>
                <div class="user-list-table el-table_border_none">
                    <el-table class="table-info"
                              border
                              :data="supplierCompanyTable"
                              v-loading="supplierTableLoading"
                              style="width: 100%">
                        <el-table-column
                                fixed
                                prop="supplierId"
                                label="供应商ID"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop="supplierName"
                                label="公司名称"
                                min-width="150px">
                        </el-table-column>
                        <el-table-column
                                prop="operatorName"
                                label="所属运营商名称"
                                min-width="150px">
                        </el-table-column>
                        <el-table-column
                                label="有效性"
                                width="220">
                            <template slot-scope="scope">
                                <p v-if="scope.row.isDeleted == 0">有效</p>
                                <p v-if="scope.row.isDeleted == 1">无效</p>
                            </template>
                        </el-table-column>
                        <!--<el-table-column-->
                        <!--label="账号数量"-->
                        <!--width="80">-->
                        <!--<template slot-scope="scope">-->
                        <!--<p>5</p>-->
                        <!--</template>-->
                        <!--</el-table-column>-->

                        <!--<el-table-column-->
                        <!--fixed="right"-->
                        <!--label="操作"-->
                        <!--width="100">-->
                        <!--<template slot-scope="scope">-->
                        <!--<el-button @click="seeListInfoClick(scope.row.id)" type="text" size="small">查看</el-button>-->
                        <!--</template>-->
                        <!--</el-table-column>-->
                    </el-table>
                    <div class="table-page-number">
                        <div class="block">
                            <el-pagination
                                    @current-change="supplierTablePageNumChange"
                                    :current-page.sync="supplierListPage.currentPage"
                                    :page-size="supplierListPage.pageSize"
                                    layout="total, prev, pager, next"
                                    :total="supplierListPage.totalPage">
                            </el-pagination>
                        </div>
                    </div>
                </div>


            </div>
        </el-main>

    </div>

</template>

<script type="text/ecmascript-6">
    import comListSearch from '../../../components/comListSearch/index.vue'

    export default {
        name: "supplierList",
        //定义模版数据
        data() {
            return {
                supplierTypeState: {  // 审核状态、用户类型、合同
                    screenContract: -1,  // 合同是否过期  0有效，1无效
                },
                supplierInfoInput: {  // 输入
                    supplierId: '',  // 供应商ID
                    corporateName: '',  // 公司名称
                    belongedOperatorName: '',  // 所属运营商名称
                },
                supplierCompanyTable: [],
                supplierTableLoading: false,
                supplierListPage: {  // 列表分页
                    pageSize: 10,  // 每页显示条目个数
                    totalPage: 1,  // 总条目个数
                    currentPage: 1,  // 当前页数
                },
            }
        },
        components: {comListSearch},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
            this.supplierOrgList()

        },
        //定义事件方法
        methods: {
//            createFilter(itemString) {
//                return (restaurant) => {
//                    return (restaurant.value.indexOf(itemString) === 0);
//                };
//            },
//            async corporateNameList(corporateNameListString, cb) {  // 获取公司名称
//                let form = {
//                    pageIndex: 1,
//                    pageSize: 9999
//                };
//                try {
//                    let res = await this.http('/galaxyOrgInfoApi/orgInfoApiPageList', form, 'POST');
//                    if (res.success) {
//                        let corporateNameListS = res.data.data;
//                        corporateNameListS.forEach(item => {
//                            item.value = item.companyName;
//                        })
//                        let corporateNameListRes = corporateNameListString ? corporateNameListS.filter(this.createFilter(corporateNameListString)) : corporateNameListS;
//                        // 调用 callback 返回建议列表的数据
//                        cb(corporateNameListRes);
//                    }
//                    else {
            // this.messageError(res.errors[0].message);
//                        this.messageError('系统出错，请稍后!');
//                    }
//
//                } catch (error) {
//                    this.messageError()
//                }
//            },
            async supplierOrgList() {  // 获取供应商列表
                this.supplierTableLoading = true;

                let form = {
                    pageIndex: this.supplierListPage.currentPage,
                    pageSize: this.supplierListPage.pageSize
                };
                if (this.supplierInfoInput.corporateName) form.companyName = this.supplierInfoInput.corporateName;  // 公司名称
                if (this.supplierInfoInput.supplierId) form.supplierId = this.supplierInfoInput.supplierId;  // 机构ID
                if (this.supplierInfoInput.belongedOperatorName) form.operatorName = this.supplierInfoInput.belongedOperatorName;  // 所属运营商名称
                if (this.supplierTypeState.screenContract != -1) form.isDeleted = this.supplierTypeState.screenContract;  // 合同是否过期


                try {
                    let res = await this.http('/galaxySupplier/supplierInfoPageList', form, 'POST');
                    if (res.success) {
                        this.supplierTableLoading = false;
                        this.supplierCompanyTable = res.data.data;
                        this.supplierListPage.totalPage = res.data.total;  // 总条目个数
                    }
//                    else {
                    // this.messageError(res.errors[0].message);
//                        this.messageError('系统出错，请稍后!');
//                    }
                } catch (error) {
                    this.messageError()
                }
            },
            supplierScreenBtnClick() {  // 搜索
                this.supplierListPage.currentPage = 1;
                this.supplierOrgList()


            },
            supplierScreenResetClick() {  // 重置
                this.supplierInfoInput.corporateName = '';  // 公司名称
                this.supplierInfoInput.supplierId = '';  // 供应商ID
                this.supplierInfoInput.belongedOperatorName = '';  // 所属运营商名称
                this.supplierTypeState.screenContract = -1;  // 合同是否过期
                this.supplierOrgList()
            },
            supplierTablePageNumChange(val) {
                this.supplierListPage.currentPage = val;
                this.supplierOrgList()
            },
        },
        //监听模版变量
        watch: {}

    }
</script>